//NextUi
import { Link, Skeleton, Card } from "@nextui-org/react";

const Portfolio = ({ title, url, image, loading }) => {
    if (loading) {
        return (
            <Card className="!rounded-2xl space-y-3 p-4 h-[340px] lg:h-[450px]" radius="lg">
            <Skeleton className="rounded-lg">
              <div className="h-[60px] rounded-lg bg-default-300"></div>
            </Skeleton>
            <Skeleton className="rounded-lg">
              <div className="h-[360px] rounded-lg bg-default-300"></div>
            </Skeleton>
          </Card>
        );
    }

    return (
        <Link isExternal href={url} className="h-[340px] lg:h-[450px] !rounded-2xl overflow-hidden shadow-lg flex-col hover:!opacity-100">
            {/* Header */}
            <div className="bg-Gray-10 w-full p-2 flex justify-between items-center gap-2">
                {/* Title */}
                <span className="w-[80%] block p-2 bg-Gray-20 text-black/40 rounded-full">{title}</span>
                {/* Colors */}
                <div className="flex gap-2">
                    <span className="w-4 h-4 bg-MainColor-50 rounded-full"></span>
                    <span className="w-4 h-4 bg-MainColor rounded-full"></span>
                    <span className="w-4 h-4 bg-black rounded-full"></span>
                </div>
            </div>
            <img src={image} alt={title} className="screen" />
        </Link>
    );
}

export default Portfolio;
