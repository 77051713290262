import { useState, useEffect } from 'react';
//internal
import BaseLayout from '../Layout/BaseLayout';
import TopBar from "../Components/TopBar";
import Portfolio from '../Components/Portfolio';
import portfolio from '../data/portfolios.json';
//nextui
import { Autocomplete, AutocompleteItem, Pagination } from "@nextui-org/react";

function PortfolioPageHeader() {
  const data = {
    title: "نمونه کارهای طراحی سایت",
    subtitle: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد"
  };
  return (
    <section className='BaseContainer flex flex-col gap-5 justify-center items-center'>
      <h2 className='text-MainColor-50 font-MorabbaMedium text-3xl text-center'>{data.title}</h2>
      <p className='text-center font-PinarMedium text-lg mb-8'>{data.subtitle}</p>
    </section>
  );
}

function PortfolioPageFilter({ value, setValue }) {
  const [siteList, setSiteList] = useState([]);

  useEffect(() => {
    setSiteList(portfolio);
  }, []);

  const categories = [{ label: "همه", value: "all" }];
  siteList.forEach(site => {
    if (!categories.find(category => category.value === site.category_slug)) {
      categories.push({ label: site.category, value: site.category_slug });
    }
  });

  return (
    <section className='BaseContainer flex flex-col gap-5 justify-center items-center'>
      <Autocomplete
        size='lg'
        placeholder="دسته بندی نمونه کارها"
        selectedKey={value}
        onSelectionChange={setValue}
      >
        {
          categories.map(
            category =>
              <AutocompleteItem key={category.value}>{category.label}</AutocompleteItem>
          )
        }
      </Autocomplete>
    </section>
  );
}

function PortfolioSiteList({ selectedCategory, currentPage, itemsPerPage, onPageChange }) {
  const [siteList, setSiteList] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadImages = (urls) => {
    const promises = urls.map((url) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = resolve;
        img.onerror = reject;
      });
    });
    return Promise.all(promises);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const filteredSiteList = selectedCategory === "all" 
        ? portfolio 
        : portfolio.filter(site => site.category_slug === selectedCategory);
      
      const imageUrls = filteredSiteList.map(site => site.image);
      await loadImages(imageUrls);
      
      setSiteList(portfolio);
      setLoading(false);
    };

    fetchData();
  }, [selectedCategory, currentPage]);

  const filteredSiteList = selectedCategory === "all" ? siteList : siteList.filter(site => site.category_slug === selectedCategory);

  const displayedItems = filteredSiteList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const totalPages = Math.ceil(filteredSiteList.length / itemsPerPage);

  return (
    <div className="flex flex-col items-center">
      <section className='BaseContainer grid md:grid-cols-2 lg:grid-cols-3 gap-8'>
        {loading
          ? Array.from({ length: itemsPerPage }).map((_, index) => (
              <Portfolio key={index} loading={true} />
            ))
          : displayedItems.map(site => (
              <Portfolio
                key={site.id}
                title={site.title}
                url={site.url}
                image={site.image}
                loading={false}
              />
            ))}
      </section>
      {totalPages > 1 && (
        <Pagination
          total={totalPages}
          initialPage={1}
          onChange={onPageChange}
          page={currentPage}
          className='mt-12'
          classNames={{
            cursor:"text-black"
          }}
        />
      )}
    </div>
  );
}

const PortfolioPage = () => {
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedCategory]);

  return (
    <BaseLayout props="!space-y-[52px] ">
      <TopBar title="نمونه کارها" />
      <PortfolioPageHeader />
      <PortfolioPageFilter value={selectedCategory} setValue={setSelectedCategory} />
      <PortfolioSiteList
        selectedCategory={selectedCategory}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        onPageChange={setCurrentPage}
      />
    </BaseLayout>
  );
}

export default PortfolioPage;
