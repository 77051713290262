//internal
import BaseLayout from "../Layout/BaseLayout"
import TopBar from "../Components/TopBar";

// //nextui
// import { Image } from "@nextui-org/react";

// // Import Swiper React components
// import { Swiper, SwiperSlide } from 'swiper/react';

// // Import Swiper styles
// import 'swiper/css';
// import 'swiper/css/pagination';

// // import required modules
// import { Pagination } from 'swiper/modules';



function AboutText({title,contet,align,image}){
  return(
    <section dir={align} className={`grid grid-cols-1 lg:grid-cols-2 BaseContainer`}>
      <div dir="rtl" className="flex flex-col gap-2 items-start justify-center">
        <h2 className="text-MainColor-50 font-MorabbaMedium text-3xl">{title}</h2>
        <p className="font-PinarMedium text-justify leading-8">{contet}</p>
      </div>
      <div className="w-full flex justify-end">
        <img src={image} className="h-[400px] mx-auto lg:mx-0 ltr:lg:ml-auto rtl:lg:mr-auto" alt="درباره تیم ما" />
      </div>
    </section>
  );
}

function AboutGallery(){
  return(
    <section className="grid grid-cols-2 md:grid-cols-3 gap-4 BaseContainer">
      <img className="AboutImages" src="assets/images/About/4.webp" alt="معین وب" />
      <img className=" AboutImages md:col-span-2" src="assets/images/About/1.webp" alt="معین وب"  />
      <img className="AboutImages md:col-span-2" src="assets/images/About/3.webp" alt="معین وب" />
      <img className="AboutImages" src="assets/images/About/2.webp" alt="معین وب" />
    </section>
  );
}

// function OurTeam(){
//   const images = [
//     { id:1 , image:"assets/images/Ourteam/1.webp"},
//     { id:2 , image:"assets/images/Ourteam/2.webp"},
//     { id:4 , image:"assets/images/Ourteam/4.webp"},
//     { id:5 , image:"assets/images/Ourteam/5.webp"},
//     { id:6 , image:"assets/images/Ourteam/6.webp"},
//     { id:7 , image:"assets/images/Ourteam/7.webp"},
//     { id:8 , image:"assets/images/Ourteam/8.webp"},
//   ];
//   return(
//     <section className="BaseContainer space-y-4">
//       <h3 className="text-MainColor-50 font-MorabbaMedium text-3xl">اعضای تیم ما</h3>
//       <Swiper
//         breakpoints={{
//           300 : {slidesPerView : 1},
//           576 : {slidesPerView : 2},
//           1200 : {slidesPerView : 3},
//           1600 : {slidesPerView : 4},
//         }}
//         loop={true}
//         pagination={{
//           dynamicBullets: true,
//         }}
//         spaceBetween={24}
//         modules={[Pagination]}
//         className="AboutUsSwiper"
//       >
//         {images.map(image=> 
//         <SwiperSlide>
//           <Image
//             key={image.id}
//             src={image.image} 
//             className="hover:!opacity-90 border-2 border-black/5 mb-4"
//           />
//         </SwiperSlide> )
//         }
//       </Swiper>
//     </section>
//   );
// }

const AboutUsPage = () => {

  
  return(
    <BaseLayout>
      <TopBar title="درباره ما" />
      <AboutText
      title="درباره تیم معین وب"
      contet="تیم معین وب متشکل از گروهی متخصص و خلاق در زمینه طراحی و توسعه وب‌سایت است. اعضای تیم ما با بهره‌گیری از جدیدترین تکنولوژی‌ها و متدهای طراحی، تلاش می‌کنند تا بهترین تجربه کاربری را برای مشتریان فراهم کنند. تیم معین وب شامل طراحان گرافیک، توسعه‌دهندگان وب، کارشناسان سئو، و متخصصان پشتیبانی است که هر کدام با دانش و تجربه‌ای که دارند، به بهبود و ارتقاء پروژه‌های مختلف کمک می‌کنند.
      ما به همکاری نزدیک با مشتریانمان اعتقاد داریم و از مرحله مشاوره اولیه تا تحویل نهایی و پشتیبانی سایت، همراه آنها هستیم. هدف ما ایجاد وب‌سایت‌هایی زیبا، کارآمد و امن است که نیازهای خاص هر کسب‌وکار را برآورده کند. با تعهد به کیفیت و ارائه خدمات بی‌نظیر، تیم معین وب همواره در تلاش است تا رضایت کامل مشتریان را جلب کند و آنها را در مسیر موفقیت آنلاین همراهی کند.
      با تکیه بر تخصص و خلاقیت تیم خود، ما قادر به طراحی سایت‌های متنوعی از جمله سایت‌های شرکتی، فروشگاهی، وبلاگی و شخصی هستیم. اگر به دنبال یک تیم حرفه‌ای و متعهد برای طراحی و توسعه وب‌سایت خود هستید، تیم معین وب آماده است تا بهترین خدمات را به شما ارائه دهد."
      image="assets/images/vectors/AboutTeam.svg"
      />
      <AboutGallery />
      <AboutText
      align="ltr"
      title="اهداف ما"
      contet="در معین وب، هدف اصلی ما ارائه خدمات طراحی و توسعه وب‌سایت با کیفیت بالا و متناسب با نیازهای هر کسب‌وکار است. ما بر ایجاد تجربه کاربری عالی، ارائه راه‌حل‌های سفارشی، تضمین امنیت و پایداری سایت‌ها تمرکز داریم. همچنین، با پشتیبانی مداوم و آموزش، همواره در کنار مشتریان هستیم تا از عملکرد بهینه وب‌سایت‌ها اطمینان حاصل کنند. ارتقاء و بهبود مستمر خدمات و کمک به رشد کسب‌وکارها از دیگر اهداف مهم ماست. ما تلاش می‌کنیم تا با طراحی وب‌سایت‌های حرفه‌ای، حضور آنلاین قوی و موثری برای مشتریان خود ایجاد کنیم."
      image="assets/images/vectors/Goal.svg"
      />
      {/* <OurTeam /> */}
    </BaseLayout>
  );
}


export default AboutUsPage;