//internal
import Faq from '../Components/Faq';
import Hero from '../Components/Hero';
import AboutUs from '../Components/AboutUs';
import OurService from '../Components/OurService';
import BaseLayout from "../Layout/BaseLayout";
import OurProperty from '../Components/OurProperty';
import OurPortfolio from '../Components/OurPortfolio';


export default function HomePage(){
  return(
    <BaseLayout>
      <Hero />
      <OurProperty />
      <AboutUs />
      <OurService />
      <OurPortfolio />
      <Faq />
    </BaseLayout>
  );
}
