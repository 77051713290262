//internal
import Header from "../Components/Header";
import Footer from "../Components/Footer";


export default function BaseLayout({children,props}){
  return(
    <>
    <Header />
    <main className={`space-y-[184px] ${props}`}>
      {children}
    </main>
    <Footer />
    </>
  );
}