
//nextui
import { Link, Button } from "@nextui-org/react";




export default function Footer(){


  //check if darkmode in change logo color
   let logoSrc = "assets/images/logos/fave-icon.png";

  const footerMenu = [

    {id:1, title:"صفحه اصلی",route:"/"},
    {id:2, title:"تماس با ما",route:"/contact"},
    {id:3, title:"درباره ما",route:"/about"},
    {id:4, title:"نمونه کارها",route:"/portfolio"},
    {id:5, title:"مقالات آموزشی",route:"/blog"},

  ];
  return(
    <footer className="sticky w-full mt-28 z-50 bg-background">
      <div className="flex flex-col gap-6 justify-center items-center">
        {/* Logo */}
        <img src={logoSrc} className="transition-all w-[140px]" alt="گروه کاژه" />
        <span className="text-xl font-PinarMedium text-center">همین امروز طراحی سایت خودتون رو به تیم ما بسپارید</span>
        <Button as={Link} href="tel:09384985990" className="!bg-MainColor !text-white">مشاوره رایگان</Button>
        {/* Footer Menu */}
        <div className="flex flex-wrap lg:flex-nowrap flex-row justify-center items-center text-xl font-PinarMedium gap-8">
        {
          footerMenu.map(menuitem=> 
          <Link
          className="!text-black dark:!text-white hover:!text-MainColor-50 transition-colors"
          href={menuitem.route}
          >
          {menuitem.title}
          </Link>
          )
        }
        </div>
        <div className="w-full flex flex-col lg:flex-row justify-center items-center gap-5 lg:justify-between bg-MainColor/30 text-white font-PinarSemiBold py-4 lg:px-20 mt-6">
          <span>تمامی حقوق متعلق به گروه کاژه می باشد</span>
          <span>تلفن : 09384985990</span>
        </div>
      </div>
    </footer>
  );
}