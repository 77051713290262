//nextui
import {Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenuToggle, NavbarMenu, NavbarMenuItem, Link, Switch, Button} from "@nextui-org/react";
//raect
import { useState, useEffect } from "react";
//icon
import { MoonIcon, SunIcon } from "@heroicons/react/24/outline"


export const Header = () => {

  //header postion
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  //usestate
  const [isMenuOpen, setIsMenuOpen] = useState(false);


  //menuitem
  const menuItem = [
    { title: "تماس با ما", rotue: "/contact" },
    { title: "نمونه کارها", rotue: "/portfolio" },
  ]

  //logo
  const logo = "assets/images/logos/fave-icon.png";

  return(
    <>
      <Navbar maxWidth="full" className={`py-2 lg:py-6 lg:w-[95%] lg:rounded-[20px] mx-auto fixed lg:top-4 md:bg-white/0  transition-colors ${scrolled ? 'shadow-sm shadow-white/10 !bg-black/80 !backdrop-blur-lg' : 'md:!backdrop-blur-0'}`} onMenuOpenChange={setIsMenuOpen}>
        <NavbarContent>
          <NavbarMenuToggle
            aria-label={isMenuOpen ? "Close menu" : "Open menu"}
            className="md:hidden"
          />
          <NavbarBrand className="justify-end md:justify-start">
            <Link href="/">
              <img src={logo} alt="معین وب" className="w-[72px] lg:w-[86px]" />
            </Link>
          </NavbarBrand>
        </NavbarContent>
        {/* Dekstop Menu */}
        <NavbarContent className="hidden md:flex gap-4" justify="center">
            {
              menuItem.map(item=> 
                <NavbarItem>
                  <Link className={`text-white hover:border-b hover:pb-2 border-MainColor transition-all font-medium ${scrolled ? 'text-white' : ''}`} href={item.rotue}>{item.title}</Link>
                </NavbarItem>
              )
            }
        </NavbarContent>
        {/* Contact Button */}
        <NavbarContent justify="end" className="hidden md:flex">
          <Button as={Link} href="tel:09384985990" className="!bg-MainColor text-white">مشاوره رایگان</Button>
        </NavbarContent>
        {/* Mobile Menu */}
        <NavbarMenu className="space-y-5 pt-16 !bg-black/70">
        {
          menuItem.map(item=> 
            <NavbarMenuItem>
              <Link className="text-white md:text-white " href={item.rotue}>{item.title}</Link>
            </NavbarMenuItem>
          )
        }
s        </NavbarMenu>
      </Navbar>
      <Button as={Link} href="tel:09384985990"  className="w-[100vw] h-12 font-PinarSemiBold fixed bottom-0 left-0 right-0 mx-auto text-black bg-MainColor md:hidden z-20 !rounded-none">مشاوره رایگان</Button>
    </>
  );
}
export default Header;