//internal
import BaseLayout from "../Layout/BaseLayout"
import TopBar from "../Components/TopBar";
import { Link } from "@nextui-org/react";

//icons
import { TelegramIcon, InstagramIcon, WhatsappIcon } from "../Components/Icons";

const ContactUsPage = () => {
  const data = {
    title:"با ما در ارتباط باشید",
    phone : "09384985990",
    mobile : "09224960662"
  }
  return(
    <BaseLayout>
      <TopBar 
      title="تماس با ما"
      />
      <div className="BaseContainer rounded-3xl shadow-large dark:shadow-lg dark:border-2 dark:border-white/5 grid grid-cols-1 lg:grid-cols-2 gap-8 p-8">
        {/* Addres */}
        <div className="text-lg lg:text-xl font-PinarMedium space-y-10"> 
          <h2 className="text-3xl text-MainColor-50 font-MorabbaMedium">{data.title}</h2>
          <div>
            <span>تلفن : </span>
            <span>{data.phone}</span>
          </div>
          <div>
            <span>تلفن : </span>
            <span>{data.mobile}</span>
          </div>
          <div className="text-MainColor-50 flex justify-center items-center gap-4">
            <Link isExternal href="https://t.me/kazheweb" className="w-14 lg:w-[52px] text-MainColor-50 bg-MainColor-10 rounded-full p-3 hover:bg-MainColor-50 hover:text-white transition-colors"> 
              <TelegramIcon /> 
            </Link>
            <Link isExternal href="https://instagram.com/kazheweb" className="w-14 lg:w-[52px] text-MainColor-50 bg-MainColor-10 rounded-full p-3 hover:bg-MainColor-50 hover:text-white transition-colors"> 
              <InstagramIcon />
            </Link>
            <Link isExternal href="https://wa.me/+989035557992" className="w-14 lg:w-[52px] text-MainColor-50 bg-MainColor-10 rounded-full p-3 hover:bg-MainColor-50 hover:text-white transition-colors"> 
              <WhatsappIcon />
            </Link>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}

export default ContactUsPage;