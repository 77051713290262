// next
import {BreadcrumbItem, Breadcrumbs, Link} from "@nextui-org/react";


function ArrowLeft(){
    return(
      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
        <path d="M15 6C15 6 9.00001 10.4189 9 12C8.99999 13.5812 15 18 15 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    );
  }

  



export default function TopBar({title, previousPages = []}){
  return(
    <nav className="h-[550px] Topbar flex flex-col justify-center items-center gap-5">
      <h1 className="text-5xl text-white font-Morabba mx-auto text-center">{title}</h1>
      <div className="flex items-center justify-center">
        <Breadcrumbs dir="rtl" size="lg" variant="solid" color="primary" classNames={{list:"bg-white/15 backdrop-blur-md"}} separator={<ArrowLeft />}>
          {/* Home */}
          <BreadcrumbItem key="/">
          <Link href="/" className="text-white">صفحه اصلی</Link>
          </BreadcrumbItem>
          {/* Previous pages */}
          {
            previousPages && previousPages.map((page, index) => (
              <BreadcrumbItem key={page.route}>
                <Link href={page.route} className="text-slate-50 text-base">
                  {page.title}
                </Link>
              </BreadcrumbItem>
            ))
          }
          {/* Current Page */}
          <BreadcrumbItem key="#">
            <span className='text-slate-50 text-base'>{title}</span>
          </BreadcrumbItem>
        </Breadcrumbs>
      </div>
    </nav>
  );
}