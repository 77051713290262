
//nextui
import {NextUIProvider} from "@nextui-org/react";

//toast
import Toast from "./Components/Toast";


//pages
import SeoPage from "./Pages/SeoPage";
import HomePage from "./Pages/HomePage";
import AboutUsPage from "./Pages/AboutUsPage";
import NotFoundPage from "./Pages/NotFoundPage";
import ContactUsPage from "./Pages/ContactUsPage";
import PortfolioPage from "./Pages/PortfolioPage";
import WebDesignPage from "./Pages/WebDesignPage";
import AdvertisingPage from "./Pages/AdvertisingPage";
import ApplicationDesignPage from "./Pages/ApplicationDesignPage";

//components
import ScrollToTop from "./Components/ScroolToTop";


//react router dom
import {Routes, Route, useNavigate} from "react-router-dom";

//react
import {useEffect} from "react";

//Aos fot animte
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);


  const navigate = useNavigate();
  return (
    <NextUIProvider navigate={navigate}>
        <Toast  />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />}/>
          <Route path="/about" element={<AboutUsPage />}/>
          <Route path="/contact" element={<ContactUsPage />}/>
          <Route path="/portfolio" element={<PortfolioPage />}/>
          <Route path="/webdesign" element={<WebDesignPage />}/>
          <Route path="/application" element={<ApplicationDesignPage />}/>
          <Route path="/seo" element={<SeoPage />}/>
          <Route path="/advertising" element={<AdvertisingPage />}/>
          <Route path="*" element={<NotFoundPage />}/>
        </Routes>
    </NextUIProvider>
  );
}

export default App;