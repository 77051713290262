//internal
import { Image, Accordion, AccordionItem, Button, Link } from "@nextui-org/react";
//heroicon
import { ChevronRightIcon } from "@heroicons/react/24/outline";

export function ServicesContainer({children,props}){
  return(
    <section className={`flex flex-col lg:flex-row child:w-full lg:child:w-1/2 items-center justify-between ${props}`}>
      {children}
    </section>
  );
}

export function ServicesTitleContent({title,content}){
  return(
    <div className="space-y-2">
      <h2 className="text-3xl font-MorabbaMedium text-MainColor-50">{title}</h2>
      <p className="text-lg font-PinarMedium text-justify">{content}</p>
    </div>
  );
}

export function ServicesImage({src,props}){
  return(
    <Image
      src={src}
      className={`w-[300px] h-[300px] ${props}`}
    />
  );
}


export function ServicesStepContainer({children}){
  return(
    <section className="grid md:grid-cols-2 lg:grid-cols-3 gap-9">
      {children}
    </section>
  );
}

export function ServicesStep({step,title,subtitle}){
  return(
    <div className="w-full flex flex-col gap-4 items-center justify-center bg-MainColor-10 p-4 rounded-[36px]">
      <div className="bg-MainColor-50 text-white rounded-full p-2">{step}</div>
      <h3 className="lg:text-2xl font-PinarBold text-black text-center">{title}</h3>
      <span className="text-sm lg:text-lg text-MainColor-50">{subtitle}</span>
    </div>
  );
}

export function Ads(){
  return(
    <div className="bg-MainColor-10 dark:bg-MainColor-50 !w-[99vw] px-[12%] mx-auto py-8 flex flex-col lg:flex-row gap-8 justify-between items-center child:!font-PinarSemiBold">
      <span className="text-center">بیش از 300 نمونه کار در زمینه های مختلف  نمونه کارهای گروه معین وب را از دست ندهید!</span>
      <Button as={Link} href="/portfolio" className="bg-white dark:bg-black text-MainColor-50 dark:text-white">مشاهده نمونه کارها</Button>
    </div>
  );
}


export function AccordionContainer({title,children,items}){
  return(
    <section className="space-y-4">
      <h4 className="text-3xl font-MorabbaMedium text-MainColor-50 mx-auto text-center">{title}</h4>
      <Accordion>
        {items.map(item=>
          <AccordionItem indicator={<ChevronRightIcon className="w-4 h-4" />} classNames={{title:"!font-PinarSemiBold text-base lg:text-lg",base:"!text-base text-justify font-PinarMedium"}} key={item.id} title={item.title} aria-label={item.title}>
            {item.content}
          </AccordionItem>
          )}
      </Accordion>
    </section>
  );
}




const ServicesLayout = () => {
  //props for daynamic pages
  return(
    <>
    </>
  );
}

export default ServicesLayout;