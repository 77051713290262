//import Marquee
import Marquee from "react-easy-marquee";
//nextui
import { Link } from "@nextui-org/react";

//react
import React, { useEffect, useState } from 'react';

import services from '../data/services.json'; // services data

function Services({ title, imgSrc, route }) {
  return (
    <Link href={route}
      className="z-10 m-20 w-[320px] h-[320px] shadow-md bg-MainColor/30 rounded-3xl p-8 flex flex-col justify-center items-center transform even:scale-110 even:rotate-6 odd:scale-110 odd:-rotate-6 hover:transform-none transition-transform">
      <img className="w-[224px] h-[224px]" src={imgSrc} alt={title} />
      <span className="font-MorabbaSemiBold text-white text-2xl">{title}</span>
    </Link>
  );
}

export const OurService = () => {
  const data = {
    title: "خدمات مجموعه ما",
    subtitle : "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی"
  };

  const [servicesList, setServicesList] = useState([]);

  useEffect(() => {
    setServicesList(services);
  }, []);

  return (
    <div
      data-aos-once="true" 
      data-aos="fade-down"
      data-aos-duration="1500"
      className="flex flex-col gap-4"
    >
      <div className="BaseContainer flex flex-col gap-4 items-start">
        <span className="font-MorabbaSemiBold text-MainColor-50 text-3xl">{data.title}</span>
        <span className="font-PinarMedium text-lg text-justify">{data.subtitle}</span>
      </div>
      <Marquee 
        height={400}
        duration={30000}
        className="p-100"
      >
        {servicesList.map(service =>
          <Services key={service.id} title={service.name} imgSrc={service.image} route={service.url} />
        )}
      </Marquee>
    </div>
  );
};

export default OurService;
