//internal
import TopBar from "../Components/TopBar";
import BaseLayout from "../Layout/BaseLayout";
import { ServicesTitleContent, ServicesImage, ServicesContainer, ServicesStepContainer, ServicesStep, AccordionContainer, Ads } from "../Layout/ServicesLayout";

const ApplicationDesignPage = () => {
  const steps = [
    {id:1, step:"مرحله اول", title:"ثبت درخواست مشاوره", subtitle:"در سایت با اطلاعات واقعی"},
    {id:2, step:"مرحله دوم", title:"تکمیل اطلاعات خود", subtitle:"در شبکه های اجتماعی"},
    {id:3, step:"مرحله سوم", title:"شروع طراحی اپلیکیشن", subtitle:"توسط کارشناسان ما"},
    {id:4, step:"مرحله چهارم", title:"تحویل اپلیکیشن به کارفرما", subtitle:"و شروع تغییرات سایت"},
    {id:5, step:"مرحله پنجم", title:"ایجاد تغییرات کارفرما", subtitle:"تا تایید سایت توسط مشتری"},
    {id:6, step:"مرحله آخر", title:"تحویل اپلیکیشن و آموزش", subtitle:"شروع تعدات ما در پشتیبانی سایت"},
  ];
  const faqs = [
    {id:1, title:"تعرفه طراحی اپلیکیشن به چه صورت است؟", content:"تعرفه طراحی اپلیکیشن به عوامل متعددی بستگی دارد که بر هزینه نهایی تأثیر می‌گذارند. پیچیدگی و میزان عملکردهای مورد نیاز، از جمله ویژگی‌های پیشرفته مانند پرداخت آنلاین و یکپارچگی با شبکه‌های اجتماعی، تأثیر زیادی بر هزینه دارند. پلتفرمی که اپلیکیشن برای آن طراحی می‌شود (اندروید، iOS یا هر دو) نیز مهم است. طراحی رابط کاربری و تجربه کاربری (UI/UX) از دیگر عوامل مؤثر هستند؛ طراحی‌های سفارشی و خاص هزینه بیشتری دارند. هزینه توسعه و نگهداری اپلیکیشن نیز شامل آپدیت‌ها و رفع اشکالات می‌شود. پشتیبانی و خدمات پس از فروش توسط شرکت توسعه‌دهنده، مانند پشتیبانی 24/7 و آموزش کاربران، نیز بر تعرفه‌ها تأثیر می‌گذارد. تجربه و تخصص تیم توسعه‌دهنده نقش مهمی در تعیین هزینه دارند؛ تیم‌های باتجربه و معتبر هزینه بیشتری دریافت می‌کنند اما کیفیت بالاتری ارائه می‌دهند. مدت زمان مورد نیاز برای توسعه اپلیکیشن نیز می‌تواند هزینه‌ها را افزایش دهد، به خصوص اگر پروژه نیاز به تحویل سریع داشته باشد. برای برآورد دقیق هزینه‌ها، نیاز به مشاوره و بررسی دقیق نیازها و ویژگی‌های مورد انتظار اپلیکیشن است تا هزینه نهایی مشخص شود."},
    {id:2, title:"مدت زمان طراحی اپلیکیشن چقدر است؟", content:"مدت زمان طراحی اپلیکیشن بستگی به چندین عامل کلیدی دارد که هرکدام می‌توانند تأثیر قابل توجهی بر زمانبندی پروژه داشته باشند. نخستین عامل پیچیدگی اپلیکیشن است؛ اپلیکیشن‌های ساده با تعداد محدودی از ویژگی‌ها معمولاً سریع‌تر طراحی و توسعه می‌یابند، در حالی که اپلیکیشن‌های پیچیده با قابلیت‌های پیشرفته مانند یکپارچگی با سیستم‌های دیگر، پرداخت آنلاین و موقعیت‌یابی نیاز به زمان بیشتری دارند. دومین عامل، پلتفرمی است که اپلیکیشن برای آن طراحی می‌شود؛ طراحی برای یک پلتفرم خاص مانند اندروید یا iOS زمان کمتری نسبت به طراحی اپلیکیشن‌های چندپلتفرمی نیاز دارد."},
    {id:3, title:"امکان فروش آنلاین در اپلیکیشن وجود دارد؟", content :"بله، امکان فروش آنلاین در اپلیکیشن‌ها وجود دارد و این ویژگی یکی از مهم‌ترین قابلیت‌هایی است که بسیاری از کسب‌وکارها به دنبال آن هستند. اپلیکیشن‌های فروش آنلاین به کاربران اجازه می‌دهند که محصولات و خدمات را مستقیماً از طریق اپلیکیشن خریداری کنند. این اپلیکیشن‌ها معمولاً شامل امکانات متنوعی هستند که فرآیند خرید را برای کاربران آسان و راحت می‌کنند."},
  ];
  return(
    <BaseLayout>
      <TopBar
        title="طراحی اپلیکیشن"
      />
      <div className="child:BaseContainer space-y-28">
        <ServicesTitleContent
        title="اپلیکیشن چیست؟"
        content="اپلیکیشن یا نرم‌افزار کاربردی (Application Software) به برنامه‌های کامپیوتری گفته می‌شود که برای انجام وظایف خاص یا ارائه خدمات خاص به کاربران طراحی شده‌اند. اپلیکیشن‌ها به گونه‌ای طراحی می‌شوند که به کاربران امکان تعامل با سیستم‌های کامپیوتری و دستگاه‌های مختلف را برای انجام فعالیت‌های روزمره، حرفه‌ای و تفریحی بدهند. این برنامه‌ها می‌توانند بر روی سیستم‌عامل‌های مختلف مانند ویندوز، مک، اندروید، iOS و لینوکس اجرا شوند. در ادامه به بررسی انواع و ویژگی‌های مختلف اپلیکیشن‌ها می‌پردازیم.

        "
        />
        <ServicesContainer>
          <ServicesTitleContent
          title="تفاوت های اپلکیشن و سایت"
          content="اپلیکیشن‌ها و وب‌سایت‌ها هر دو ابزارهای دیجیتالی مفیدی هستند، اما تفاوت‌های مهمی دارند. اپلیکیشن‌ها نیاز به نصب دارند و از فروشگاه‌های اپلیکیشن دانلود می‌شوند، در حالی که وب‌سایت‌ها از طریق مرورگر وب بدون نیاز به نصب قابل دسترسی‌اند. اپلیکیشن‌ها تجربه کاربری بهتری ارائه می‌دهند و می‌توانند از قابلیت‌های بومی دستگاه مانند دوربین و GPS استفاده کنند، اما هزینه‌های توسعه و نگهداری بالاتری دارند. وب‌سایت‌ها نیازی به نصب و بروزرسانی دستی ندارند و به‌صورت مرکزی بروزرسانی می‌شوند، اما عملکردشان به سرعت و پایداری اینترنت وابسته است. انتخاب بین آنها بستگی به نیازها و اهداف کاربران دارد."
          />
          <ServicesImage
            src="assets/images/vectors/application/app-1.svg"
            props="!ml-auto"
          />
        </ServicesContainer>
        {/* Ads */}
        <Ads />
        <ServicesContainer>
          <ServicesImage
            src="assets/images/vectors/application/app-2.svg"
            props="!ml-auto"
          />
          <ServicesTitleContent
          title="ویژگی های یک اپلیکیشن"
          content="یک اپلیکیشن موفق باید دارای ویژگی‌های کلیدی باشد که تجربه کاربری را بهبود بخشد. این ویژگی‌ها شامل رابط کاربری ساده و کاربرپسند، عملکرد سریع و پاسخگو، امنیت بالا برای حفاظت از داده‌های کاربر، سازگاری با سیستم‌عامل‌ها و دستگاه‌های مختلف، امکان بروزرسانی آسان و منظم برای افزودن قابلیت‌های جدید و رفع اشکالات، و قابلیت استفاده در حالت آفلاین برای دسترسی به اطلاعات و خدمات بدون نیاز به اتصال اینترنت است. همچنین، اپلیکیشن باید از قابلیت‌های بومی دستگاه مانند دوربین، GPS و نوتیفیکیشن‌ها بهره‌برداری کند تا تجربه کاربری کامل‌تری ارائه دهد."
          />
        </ServicesContainer>
        <ServicesContainer>
          <ServicesTitleContent
          title="مزایای داشتن اپلیکیشن برای کسب و کار"
          content="داشتن اپلیکیشن برای کسب‌وکارها مزایای بسیاری دارد که می‌تواند به رشد و موفقیت آنها کمک کند. یکی از اصلی‌ترین مزایا، دسترسی آسان‌تر و سریع‌تر به خدمات و محصولات کسب‌وکار برای مشتریان است، که این امر موجب افزایش رضایت و وفاداری مشتریان می‌شود. اپلیکیشن‌ها امکان ارسال نوتیفیکیشن‌های مستقیم را فراهم می‌کنند، که این ویژگی به کسب‌وکارها کمک می‌کند تا به‌صورت لحظه‌ای با مشتریان خود در ارتباط باشند و آنها را از تخفیف‌ها، رویدادها و اخبار جدید مطلع کنند. همچنین، اپلیکیشن‌ها می‌توانند تجربه کاربری بهتری نسبت به وب‌سایت‌ها ارائه دهند. این موضوع باعث می‌شود که فرآیند خرید و تعامل با کسب‌وکار سریع‌تر و راحت‌تر شود. داشتن اپلیکیشن همچنین به کسب‌وکارها کمک می‌کند تا داده‌های بیشتری از رفتار و نیازهای مشتریان جمع‌آوری کنند و بر اساس این داده‌ها استراتژی‌های بازاریابی خود را بهبود بخشند. در نهایت، اپلیکیشن‌ها به کسب‌وکارها امکان می‌دهند تا از رقبا متمایز شوند و تصویر مدرن و به‌روزتری از خود به مشتریان ارائه دهند."
          />
          <ServicesImage
            src="assets/images/vectors/application/app-3.svg"
            props="!ml-auto"
          />
        </ServicesContainer>
        <ServicesStepContainer>
          {
            steps.map
            (item=>
            <ServicesStep
              key={item.id}
              step={item.step}
              title={item.title}
              subtitle={item.subtitle}
            />  
            )
          }
        </ServicesStepContainer>
        <AccordionContainer
          title="سوالات متداول"
          items={faqs}
        >
        </AccordionContainer>
      </div>
    </BaseLayout>
  );
}

export default ApplicationDesignPage;