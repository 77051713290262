
//nextui
import { Button, Link } from "@nextui-org/react";

export default function Hero() {
  return (
    <section className="HeroBackground flex justify-center items-center">
      <div className="flex flex-col justify-center items-center gap-y-4 lg:gap-y-10 ">
        <h2 ata-aos-once="true" data-aos="zoom-in" data-aos-duration="1000" className="font-MorabbaMedium text-3xl lg:text-5xl text-MainColor-50 text-center">
        نوآوری و خلاقیت مدرن
        </h2>
        <h1 ata-aos-once="true" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="1000" className="font-MorabbaMedium text-4xl lg:text-[64px] text-white">
         گروه کاژه
        </h1>
        <p data-aos-once="true" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1500" className="font-PinarSemiBold lg:text-xl text-white w-[360px] md:w-[600px] lg:w-[800px] text-center !leading-10">
        طراحی سایت حرفه‌ای ، هنری است که به کمک آن یک دنیای دیجیتال شگفت‌انگیز را خلق می‌کنیم. این هنر ، ترکیبی است از خلاقیت ، تخصص فنی و فهم عمیق درباره نیازهای کاربران.تیم طراحی سایت کاژه ، با برترین تکنیک‌ها و رویکردهای طراحی ، صفحاتی را خلق میکند که نه تنها زیبا و جذاب هستند ، بلکه به طور همزمان کارایی و سازگاری بالایی با تمام دستگاه‌ها و مرورگرها را نیز دارند.        </p>
        <Button as={Link} href="/portfolio"  data-aos-once="true" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="2000" className="bg-gradient-to-bl from-MainColor to-MainColor-50 font-PinarSemiBold text-lg p-6 rounded-full mt-10 text-white">
          مشاهده نمونه کارها
        </Button>
      </div>
    </section>
  );
}