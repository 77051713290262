//nextui
import { Button, Link } from "@nextui-org/react";

export default function NotFoundPage(){
  return(
    <>
    <div className="w-full flex flex-col gap-8 justify-center items-center py-24">
      <img src="assets/images/vectors/404.svg" className="w-[593px]" alt="404" />
      <h1 className="text-4xl lg:text-5xl font-MorabbaMedium">صفحه پیدا نشد </h1>
      <Button size="lg" className="text-black" color="primary" as={Link} href="/" >بازگشت به سایت</Button>
    </div>
    </>
  );
}