// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import { EffectCoverflow } from 'swiper/modules';

// Internal
import Portfolio from "./Portfolio";

//data
import portfolio from '../data/portfolios.json';

import { useState, useEffect } from 'react';

export function MySwiper({ items }) {
  return (
    <>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        breakpoints={{
          300: { slidesPerView: 1.2 },
          576: { slidesPerView: 1.6 },
          900: { slidesPerView: 3 },
          1200: { slidesPerView: 3 },
          1600: { slidesPerView: 4 },
        }}
        coverflowEffect={{
          rotate: 0,
          modifier: 7,
        }}
        modules={[EffectCoverflow]}
        className="mySwiper"
      >
        {
          items.map(item => (
            <SwiperSlide key={item.id} className='!rounded-xl overflow-hidden'>
              {item}
            </SwiperSlide>
          ))
        }
      </Swiper>
    </>
  );
}

export default function OurPortfolio() {

  const data = {
    title: "گوشه ای از نمونه کارهای ما",
    subtitle: "در معین وب، با بهره‌گیری از تخصص و خلاقیت تیم طراحی، صدها وب‌سایت زیبا و حرفه‌ای برای مشاغل مختلف ایجاد کرده‌ایم. سایت‌های ما جذاب، کاربرپسند، با کارایی و قابلیت شخصی‌سازی بالا هستند. شما می‌توانید قبل از خرید، نمونه کارهای ما را بررسی و سایت مورد نظر خود را انتخاب کنید. با معین وب، کسب‌وکار خود را ارتقا دهید.",
  };

  const [items, setItems] = useState([]);

  useEffect(() => {
    const randomItems = getRandomItems(portfolio, 12);
    setItems(randomItems);
  }, []);

  const getRandomItems = (array, num) => {
    const shuffled = [...array].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
  };

  return (
    <section
      data-aos-once="true"
      data-aos="fade-down"
      data-aos-easing="linear"
      data-aos-duration="1500"
    >
      <div className="flex flex-col justify-center items-center gap-5 mb-5 BaseContainer">
        <h3 className="text-MainColor-50 font-MorabbaSemiBold text-2xl lg:text-3xl mx-auto text-center">{data.title}</h3>
        <span className="text-base lg:text-lg font-PinarSemiBold w-full lg:w-[70%] text-center">{data.subtitle}</span>
        <div className="flex gap-4 child:px-4 child:py-2 child:rounded-full text-black child:font-PinarSemiBold">
          <div className="bg-MainColor"> متفاوت </div>
          <div className="bg-MainColor-10"> سریع </div>
          <div className="bg-MainColor-50"> جذاب </div>
        </div>
      </div>
      <MySwiper
        items={items.map(site => <Portfolio key={site.id} title={site.title} image={site.image} url={site.url} />)}
      />
    </section>
  );
}
