//icons
import { WebDesign02Icon, ComputerPhoneSyncIcon, Rocket01Icon, CustomerService02Icon } from "./Icons"

function Property({icon,title,subtitle}){
  return(
    <div className="bg-MainColor/20 text-white   flex flex-col justify-center items-center gap-4 p-7 rounded-3xl">
      <div className="w-[58px] md:w-[64px] lg:w-[72px]">{icon}</div>
      <span className="font-MorabbaMedium text-xl md:text-2xl lg:text-xl xl:text-2xl text-center">{title}</span>  
      <p className="text-base text-center">{subtitle}</p>
    </div>
  );
}

export default function OurProperty(){
  const data = {
    title : "چرا گروه کاژه",
    subtitle : "مزایای انتخاب خدمات ما "
  }
  const items = [
    {
       icon : <WebDesign02Icon/>,
       title:"خدمات طراحی",
       subtitle : "طراحی صفحات وب زیبا و کارآمد"
    },
    {
       icon : <Rocket01Icon/>,
       title:"سرور پرسرعت",
       subtitle : "بارگذاری سریع و دسترسی همیشگی"
    },
    {
       icon : <CustomerService02Icon/>,
       title:"خدمات پشتیبانی",
       subtitle : "پشتیبانی و آموزش صفر تا صدی"
    },
    {
        icon : <ComputerPhoneSyncIcon/>,
        title:"طراحی اختصاصی",
        subtitle : "طراحی متناسب با کسب و کار شما"
    },
  ]
  return(
    <section className="BaseContainer flex flex-col justify-center items-center gap-4" data-aos-once="true" data-aos="fade-down" data-aos-duration="1500">
    {/* Title and SubTitle */}
    <span className="font-Morabba text-2xl">{data.subtitle}</span>
    <h2  className="text-MainColor-50 font-MorabbaSemiBold text-4xl">{data.title}</h2>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-full gap-4 mt-4">
    {
      items.map(item=> <Property icon={item.icon} title={item.title} subtitle={item.subtitle} />)
    }
    </div>
    </section>
  );
}