//nextui
import {Accordion, AccordionItem} from "@nextui-org/react";

//heroicon
import { ChevronRightIcon } from "@heroicons/react/24/outline";

const Faq = () => {

  const accordionItems = [
    { id:1, title:" هزینه طراحی سایت چقدر است؟", text:"هزینه طراحی سایت بسته به نوع سایت (شرکتی، فروشگاهی و ...)، امکانات و افزونه‌های مورد استفاده، و خدمات سئو و بهینه‌سازی متغیر است. برای اطلاعات دقیق‌تر نیاز است که جزئیات پروژه و نیازهای خاص آن مشخص شود." },
    { id:2, title:"مدت زمان طراحی سایت چقدر است؟", text:"مدت زمان طراحی سایت معمولاً بین 2 تا 6 هفته است. این زمان به پیچیدگی پروژه، تعداد صفحات، امکانات ویژه و همکاری مشتری در ارائه محتوا و بازخورد بستگی دارد." },
    { id:3, title:"امکان فروش آنلاین در سایت وجود دارد؟", text:"بله، امکان فروش آنلاین با ایجاد فروشگاه، مدیریت سفارشات، و تنظیم روش‌های پرداخت و حمل و نقل در سایت فراهم است. افزونه‌هایی مانند ووکامرس برای این منظور استفاده می‌شود." },
    { id:4, title:"آیا از سایت من نگهداری خواهید کرد؟", text:"بله، خدمات پشتیبانی و نگهداری سایت تا یک سال پس از طراحی نیز ارائه می‌شود. این خدمات شامل به‌روزرسانی‌های دوره‌ای، رفع اشکالات و امنیت سایت است." },
  ]

  return(
    <section
      data-aos-once="true" 
      data-aos="fade-down"
      data-aos-duration="1500"
      className="BaseContainer flex flex-col md:flex-row justify-between items-center"
    >
      <div className="w-full md:w-1/2 space-y-6">
        <h3 className="text-MainColor-50 text-2xl text-center lg:text-3xl font-MorabbaSemiBold">سوالات متداول</h3>
        <Accordion>
          {
            accordionItems.map
            (
            item=>
            <AccordionItem classNames={{title:"!font-PinarSemiBold text-base lg:text-lg",base:"!text-base text-justify font-PinarMedium"}} indicator={<ChevronRightIcon className="w-4 h-4" />} key={item.id} aria-label={item.id} title={item.title}>
                {item.text}
            </AccordionItem>    
            )
          }
        </Accordion>
      </div>
      <div className="w-full md:w-1/2">
        <img src="assets/images/vectors/Faq.svg" alt="faq" className="w-full" />
      </div>
    </section>
  );
}

export default Faq;