//internal
import TopBar from "../Components/TopBar";
import BaseLayout from "../Layout/BaseLayout";
import { ServicesTitleContent, ServicesImage, ServicesContainer, ServicesStepContainer, ServicesStep, AccordionContainer, Ads } from "../Layout/ServicesLayout";

const WebDesignPage = () => {
  const steps = [
    {id:1, step:"مرحله اول", title:"ثبت درخواست مشاوره", subtitle:"در سایت با اطلاعات واقعی"},
    {id:2, step:"مرحله دوم", title:"تکمیل اطلاعات خود", subtitle:"در شبکه های اجتماعی"},
    {id:3, step:"مرحله سوم", title:"شروع طراحی سایت", subtitle:"توسط کارشناسان ما"},
    {id:4, step:"مرحله چهارم", title:"تحویل سایت به کارفرما", subtitle:"و شروع تغییرات سایت"},
    {id:5, step:"مرحله پنجم", title:"ایجاد تغییرات کارفرما", subtitle:"تا تایید سایت توسط مشتری"},
    {id:6, step:"مرحله آخر", title:"تحویل سایت و آموزش", subtitle:"شروع تعدات ما در پشتیبانی سایت"},
  ];
  const faqs = [
    {id:1, title:" مراحل طراحی سایت در شرکت شما چگونه است؟", content:"فرآیند طراحی سایت ما شامل ثبت درخواست مشاوره، تکمیل اطلاعات در شبکه‌های اجتماعی، شروع طراحی توسط کارشناسان، تحویل اولیه سایت، اعمال تغییرات پیشنهادی و تایید نهایی، و در نهایت تحویل سایت و آموزش‌های لازم به همراه پشتیبانی کامل است."},
    {id:2, title:" چگونه می‌توانم درخواست مشاوره خود را ثبت کنم؟", content:"برای ثبت درخواست مشاوره، کافی است به وب‌سایت ما مراجعه کرده و فرم مشاوره را با اطلاعات واقعی خود تکمیل کنید. پس از ثبت درخواست، تیم ما با شما تماس خواهد گرفت."},
    {id:3, title:" اطلاعات تکمیلی را چگونه ارسال کنم؟", content:"شما می‌توانید اطلاعات تکمیلی مورد نیاز را از طریق شبکه‌های اجتماعی مانند تلگرام یا واتساپ با ما به اشتراک بگذارید. این اطلاعات به ما کمک می‌کند تا پروژه شما را بهتر بشناسیم و طراحی مناسبی ارائه دهیم."},
    {id:4, title:"آیا پس از تحویل سایت، آموزش‌های لازم ارائه می‌شود؟", content:"بله، پس از تحویل نهایی سایت، آموزش‌های لازم برای مدیریت و به‌روزرسانی سایت به شما ارائه می‌شود تا بتوانید به راحتی محتوای سایت خود را مدیریت کنید."},
    {id:5, title:" آیا خدمات پشتیبانی نیز ارائه می‌دهید؟" , content:"بله، پس از تحویل سایت، تعهدات ما در زمینه پشتیبانی سایت آغاز می‌شود. تیم پشتیبانی ما همیشه آماده است تا در صورت نیاز، مشکلات شما را حل کند و سایت شما را به‌روز نگه دارد." },
    {id:6, title:" آیا سایت‌های شما با موبایل و تبلت سازگار هستند؟" , content:"بله، تمامی سایت‌های ما به صورت ریسپانسیو طراحی می‌شوند، به طوری که در تمامی دستگاه‌ها (کامپیوتر، تبلت، موبایل) به خوبی نمایش داده شوند و تجربه کاربری عالی را فراهم کنند." },    
    {id:7, title:" چه نوع سایت‌هایی را می‌توانید طراحی کنید؟" , content:"ما توانایی طراحی انواع سایت‌ها از جمله سایت‌های شرکتی، فروشگاهی، شخصی، وبلاگی و غیره را داریم. با توجه به نیازها و اهداف شما، بهترین راه‌حل را ارائه خواهیم داد." },    
  ];
  return(
    <BaseLayout>
      <TopBar
        title="طراحی سایت"
      />
      <div className="child:BaseContainer space-y-28">
        <ServicesTitleContent
        title="سایت چیست؟"
        content="یک سایت (وب‌سایت) مجموعه‌ای از صفحات اینترنتی است که از طریق یک آدرس منحصر به فرد به نام دامنه (مانند www.example.com) در دسترس قرار می‌گیرد. هر سایت به شما این امکان را می‌دهد تا اطلاعات، تصاویر، ویدئوها و محتواهای متنوعی را به کاربران نمایش دهید. سایت‌ها می‌توانند برای اهداف مختلفی مانند کسب‌وکار، اطلاع‌رسانی، آموزش و سرگرمی استفاده شوند. با داشتن یک سایت، می‌توانید کسب‌وکار خود را به صورت آنلاین معرفی کنید، محصولات و خدمات خود را به فروش برسانید و با مخاطبان خود در ارتباط باشید. طراحی زیبا و کاربرپسند سایت به کاربران کمک می‌کند تا به راحتی به اطلاعات مورد نیاز خود دسترسی پیدا کنند و تجربه‌ی بهتری از بازدید سایت شما داشته باشند. با استفاده از سیستم‌های مدیریت محتوا (CMS)، می‌توانید به سادگی سایت خود را مدیریت و به‌روزرسانی کنید، بدون نیاز به دانش فنی خاص. در دنیای امروز، داشتن یک سایت به شما کمک می‌کند تا بیشتر دیده شوید، مخاطبان بیشتری جذب کنید و کسب‌وکار خود را گسترش دهید."
        />
        <ServicesContainer>
          <ServicesTitleContent
          title="ویژگی های معین وب"
          content="معین وب با سال‌ها تجربه در طراحی و توسعه وب‌سایت‌های حرفه‌ای، خدماتی متنوع و با کیفیت را به مشتریان خود ارائه می‌دهد. طراحی‌های اختصاصی و منحصربه‌فرد، استفاده از جدیدترین تکنیک‌ها و ابزارهای طراحی، و تضمین امنیت بالا، از جمله ویژگی‌های برجسته معین وب است. ما با ارائه پشتیبانی ۲۴ ساعته، هاستینگ پرسرعت، و قابلیت شخصی‌سازی آسان، اطمینان می‌دهیم که وب‌سایت شما همیشه به‌روز و کارآمد باشد. معین وب همچنین با تنوع قالب‌ها و افزونه‌ها، خدمات سئو و بهینه‌سازی، و آموزش‌های لازم، به شما کمک می‌کند تا حضور آنلاین خود را به بهترین شکل ممکن مدیریت کنید و کسب‌وکارتان را به سطح جدیدی ارتقا دهید."
          />
          <ServicesImage
            src="assets/images/vectors/webdesign/site-1.svg"
            props="!ml-auto"
          />
        </ServicesContainer>
        {/* Ads */}
        <Ads />
        <ServicesContainer>
          <ServicesImage
            src="assets/images/vectors/webdesign/site-2.svg"
            props="!ml-auto"
          />
          <ServicesTitleContent
          title="امکانات سایت های ما"
          content="سایت‌های ما با طراحی اختصاصی و منحصربه‌فرد، قالب‌ها و افزونه‌های متنوع، و امکان ایجاد فروشگاه آنلاین با مدیریت محصولات و سفارشات، به شما کمک می‌کنند تا بهترین تجربه کاربری را ارائه دهید. سیستم مدیریت محتوای وردپرس به شما اجازه می‌دهد بدون نیاز به دانش فنی، محتوای سایت خود را به‌روز کنید. خدمات پشتیبانی ۲۴ ساعته، تضمین امنیت اطلاعات با پروتکل‌های امنیتی و گواهینامه‌های SSL، و استفاده از سرورهای پرسرعت برای عملکرد بهینه، از دیگر ویژگی‌های سایت‌های ماست. همچنین، با ارائه آموزش‌های جامع و بروزرسانی‌های منظم، سایت شما همیشه به‌روز و کارآمد خواهد بود. تمامی سایت‌ها به صورت ریسپانسیو طراحی می‌شوند تا در تمامی دستگاه‌ها به خوبی نمایش داده شوند. این امکانات متنوع و پیشرفته به شما کمک می‌کنند تا حضور آنلاین قوی و موثری داشته باشید و کسب‌وکار خود را به بهترین شکل ممکن مدیریت کنید."
          />
        </ServicesContainer>
        <ServicesContainer>
          <ServicesTitleContent
          title="مراحل طراحی سایت در شرکت ما"
          content="فرآیند طراحی سایت در شرکت ما شامل چند مرحله ساده و کارآمد است. ابتدا شما درخواست مشاوره خود را با اطلاعات واقعی در سایت ما ثبت می‌کنید. سپس اطلاعات تکمیلی خود را از طریق شبکه‌های اجتماعی با ما به اشتراک می‌گذارید. بعد از دریافت اطلاعات کامل، کارشناسان ما طراحی سایت شما را آغاز می‌کنند. سایت اولیه به شما تحویل داده می‌شود تا نظرات و پیشنهادات خود را اعمال کنید. پس از ایجاد تغییرات مورد نیاز و تایید نهایی شما، سایت به شما تحویل داده می‌شود و آموزش‌های لازم برای مدیریت آن ارائه می‌شود. همچنین، تعهدات ما در پشتیبانی سایت آغاز می‌شود تا در هر زمان نیاز به کمک داشتید، در کنار شما باشیم. این مراحل به ما کمک می‌کند تا یک سایت زیبا، کارآمد و متناسب با نیازهای شما ایجاد کنیم و از موفقیت شما در دنیای آنلاین مطمئن شویم."
          />
          <ServicesImage
            src="assets/images/vectors/webdesign/site-3.svg"
            props="!ml-auto"
          />
        </ServicesContainer>
        <ServicesStepContainer>
          {
            steps.map
            (item=>
            <ServicesStep
              key={item.id}
              step={item.step}
              title={item.title}
              subtitle={item.subtitle}
            />  
            )
          }
        </ServicesStepContainer>
        <AccordionContainer
          title="سوالات متداول"
          items={faqs}
        >
        </AccordionContainer>
      </div>
    </BaseLayout>
  );
}

export default WebDesignPage;