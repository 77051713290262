//nextui
import { Button, Link } from "@nextui-org/react";

export default function AboutUs(){
  const data = {
    title : "تجربه‌ای جدید د  گروه کاژه",
    subtitle : "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی",
    image : "assets/images/app.jpg"
  }
  return(
    <section className="BaseContainer grid lg:grid-cols-2 gap-8 items-center" data-aos-once="true" data-aos="fade-down" data-aos-duration="1500">
      <div className="flex flex-col items-start gap-5">
        <span className="font-MorabbaSemiBold text-3xl text-MainColor">{data.title}</span>
        <p  className="font-PinarMedium text-justify text-lg">{data.subtitle}</p>
        <Button as={Link} href="/about" color="primary" className="text-white font-PinarSemiBold self-end">درباره ما</Button>
      </div>
      <div>
        <img className="w-full md:h-[350px] lg:h-[380px] xl:h-[440px] rounded-xl object-cover" src={data.image} alt="ویدئو" />
      </div>
    </section>
  );
}